<template>
  <div class="bgf8 p-b-30 main">
    <div class="w1200">
      <div class="p-t-30 p-b-13">
        <span class="font30 color333">确认订单信息</span
        ><span class="font14 color999 pl20"
          >请在30分钟内完成支付，否则订单会被自动</span
        >
      </div>
      <div class="m-box m-head">
        <p class="font24 color333">购课信息</p>
        <div class="m-tab m-t-15">
          <div class="con_title flex-cen font18 color666">
            <div class="flex-1 flex-between p-l-70">课程信息</div>
            <div class="flex-around flex-1">
              <div>价格</div>
              <div>有效期</div>
              <div>实付</div>
            </div>
          </div>
          <div class="con_main flex p-30">
            <div class="flex flex-1">
              <img class="img_box" :src="queryList.coverImg" alt="" />
              <div class="m-l-19">
                <div class="font16 color333">
                  {{ queryList.title }}
                </div>
                <div class="font12 color999 p-t-8 p-b-8">
                  {{ queryList.teacher }}｜{{ queryList.label }}
                </div>
                <div class="font12 color999 flex">
                  <div class="icon m-r-5">
                    <div class="triangle"></div>
                  </div>
                  共有{{ queryList.videoCount }}节课时
                </div>
              </div>
            </div>
            <div class="flex-evenly flex-1 font16 color333">
              <div class="p-l-65">¥ {{ queryList.salePrice }}</div>
              <div class="p-l-89">永久有效</div>
              <div class="p-l-88 colorfa0">¥ {{ queryList.salePrice }}</div>
            </div>
          </div>
        </div>

        <!-- 各个平台接口反的支付 -->
        <div class="m-t-35 pay_con" v-if="user.merchants_id == 10">
          <p class="font24 color333">支付方式</p>
          <div class="m-t-15 pay_box flex">
            <div
              class="pay p-r-80 m-r-30"
              :class="payBtn == i ? 'active' : ''"
              v-for="(item, i) in dataList"
              :key="i"
              @click="btnPayWb(item.id, (payBtn = i))"
            >
              <div class="flex-around m-l-30 p-t-25">
                <img class="img50" :src="item.icon" alt="" />
                <div class="font16 color333 weight">{{ item.pay_name }}</div>
              </div>
              <div class="m-t-35 p-l-10">
                <div class="color333 font16">{{ item.pinying }}</div>
                <div class="color999 font14">{{ item.reamk }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-t-35 pay_con" v-else>
          <p class="font24 color333">支付方式</p>
          <div class="m-t-15 pay_box flex">
            <div
              class="pay p-r-80 m-r-30"
              :class="payBtn == i ? 'active' : ''"
              v-for="(item, i) in payList"
              :key="i"
              @click="btnPay((payBtn = i))"
            >
              <div class="flex-around m-l-30 p-t-25">
                <img class="img50" :src="item.img" alt="" />
                <div class="font16 color333 weight">{{ item.name }}</div>
              </div>
              <div class="m-t-35 p-l-10">
                <div class="color333 font16">{{ item.pay }}</div>
                <div class="color999 font14">{{ item.intr }}</div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div class="flex-right">
          <div>
            <div class="font16 color999 m-b-22">
              需付金额：<span class="font30 colorfa0"
                >¥ {{ queryList.salePrice }}</span
              >
            </div>
            <Button
              size="large"
              style="width: 200px"
              shape="circle"
              type="error"
              @click="payMentOrde()"
              v-if="user.merchants_id == 10"
              >立刻支付</Button
            >
            <Button
              size="large"
              style="width: 200px"
              type="error"
              shape="circle"
              @click="payMent()"
              v-else
              >立刻支付</Button
            >
          </div>
        </div>
      </div>
    </div>
    <div>
      <Modal
        class="no-login-box"
        v-model="payData"
        :closable="false"
        :scrollable="true"
        :footer-hide="true"
        :mask-closable="false"
        :styles="{ top: '300px' }"
        width="360"
        @on-visible-change="qrChange"
      >
        <p
          class="item-close"
          style="
            width: 20px;
            height: 20px;
            position: absolute;
            right: -21px;
            top: -21px;
            cursor: pointer;
          "
          @click="payData = false"
        >
          <img src="../assets/images/x.png" alt="" />
        </p>
        <!-- <div ref="qr" class="item-img"></div> -->
        <p
          class="item-img"
          style="display: flex;
    align-content: center;
    justify-content: center;
}"
        >
          <img :src="img_url" alt="" />
        </p>
        <div
          class="tip-bg"
          style="display: flex;
    align-content: center;
    justify-content: center;
}"
        >
          <p>请使用{{ reamk }}扫描</p>
          <p>二维码以完成支付</p>
        </div>
      </Modal>
    </div>
    <!-- <pay /> -->
    <ModelLos :isShowLos="isShowLos" @faBtnLos="faBtnLos()" />
    <ModelSucc :isShow="isShow" @faBtn="faBtns()" />
  </div>
</template>

<script>
import pay from "../components/Pay";
import ModelSucc from "../components/PaySucc";
import ModelLos from "../components/ModelShop";
import zfbImg from "../assets/images/zfb.png";
import wxImg from "../assets/images/wx.png";
import { doPay, searchOrderApi } from "@/api/user";
export default {
  data() {
    return {
      isShowLos: false,
      isShow: false,
      payData: false,
      payId: 1,
      payModal: false,
      wxInx: "",
      user: {},
      reamk: "",
      queryList: {},
      timer: null, //查询支付状态的定时器
      payBtn: 0,
      timer: null, //查询支付状态的定时器
      dataList: [],
      img_url: "",
      reamk: "",
      order_number: "",
      payList: [
        {
          name: "支付宝支付",
          img: zfbImg,
          pay: "支付宝扫码支付",
          intr: "使用支付宝app扫描二维",
        },
        {
          name: "微信支付",
          img: wxImg,
          pay: "微信扫码支付",
          intr: "使用微信app扫描二维",
        },
      ],
    };
  },

  components: {
    pay,
    ModelSucc,
    ModelLos,
  },
  mounted() {
    this.queryList = this.$route.query;
    console.log("this.queryList", this.queryList);
    let user = localStorage.getItem("userInfo");
    this.user = JSON.parse(user);
    // this.$nextTick(() => {
    //   this.bindQRCode();
    // });


    // window.addEventListener("message", function (messageEvent) {
    //   let peyYes = messageEvent?.data?.peyYes;
    //   if(peyYes) {
    //     // 成功逻辑处理
    //   }
    // });
  },
  methods: {
    // 平台外部返回支付方式
    External() {
      $.ajax({
        url: "https://v.xasxlyy.com/YiZiXue/GetPayInfo",
        type: "post",
        dataType: "json",
      }).then((res) => {
        this.dataList = res.data;
        console.log("this.dataList", this.dataList);
      });
    },

    faBtnLos() {
      this.isShowLos = false;
      clearInterval(this.timer);
    },
    faBtns() {
      this.isShow = false;
      clearInterval(this.timer);
    },
    // 龙津接口轮询
    qrDialogChange() {
      this.timer = setInterval(() => {
        searchOrderApi({
          user_id: this.user.user_id,
          course_id: this.$route.query.courseId,
        })
          .then((res) => {
            if (res.code == "200") {
              let payResult = res.data;
              if (payResult == 1) {
                clearInterval(this.timer);
                this.isShow = true;
                // this.$router.replace({
                //   path: "/Personal/Purchase",
                //   query: {},
                // });
              } else {
                console.log("支付未完成", payResult);
                this.isShowLos = true;
                // this.$router.replace({
                //   path: "/Personal/Purchase",
                //   query: {},
                // });
              }
            }
          })
          .catch((err) => {});
      }, 2000);
    },

    btnPay(inx) {
      this.wxInx = inx;
      console.log("inx", this.wxInx);
    },
    btnPayWb(id) {
      this.payId = id;
      console.log("id", this.payId);
    },
    payMent() {
      // 新龙支付
      if (this.wxInx == 0) {
        let arr = {
          is: 1,
          courseId: this.queryList.courseId,
          payType: this.wxInx == 1 ? "0" : "1",
          merchants_id: this.user.merchants_id,
          name: this.user.user_id,
        };
        doPay(arr).then((res) => {
          if (res.code == 200) {
            this.qrDialogChange();
            const div = document.createElement("divform");
            div.innerHTML = res.data.body;
            document.body.appendChild(div);
            document.forms["punchout_form"].setAttribute("target", "_blank");
            document.forms["punchout_form"].submit();
          }
        });
      } else {
        window.open(
          "https://dev.yzxapp.com/pay/index.html?account=CDWD220531&secretKey=F93DC716AB16C315912B830A25E40AB5&courseId=" +
            this.queryList.courseId +
            "&payType=0&tradeType=Native&userName=" +
            this.user.user_id +
            "_" +
            this.user.merchants_id +
            "&source=" +
            this.user.merchants_id,
          "_blank"
        );
        this.qrDialogChange();
      }
    },

    // 其余支付
    payMentOrde() {
      if (this.user.merchants_id == 10) {
        $.ajax({
          url: "https://v.xasxlyy.com/YiZiXue/PayInfo",
          type: "post",
          dataType: "json",
          data: {
            pay_id: this.payId,
            source: "pc",
            user_id: this.user.user_id,
            shipin_name: this.queryList.title,
            shipin_num: this.queryList.courseId,
            total: this.queryList.salePrice,
          },
        }).then((res) => {
          this.img_url = res.data.data.img_url;
          this.reamk = res.data.data.reamk;
          this.order_number = res.data.order_number;
          this.payData = true;

          console.log("this.dataListxxxxxx", res);
        });
      }
    },
    // 新龙轮巡
    qrChange(isShow) {
      console.log("isShow", isShow);
      if (isShow) {
        this.timer = setInterval(() => {
          $.ajax({
            url: "https://v.xasxlyy.com/YiZiXue/GetPayStatus",
            type: "post",
            dataType: "json",
            data: {
              yzx_order_number: this.order_number,
            },
          })
            .then((res) => {
              if (res.code == "200") {
                let status = res.data.status;
                if (status == 1) {
                  clearInterval(this.timer);
                  this.isShow = true;
                } else {
                  this.isShowLos = true;
                }
              }
            })
            .catch((err) => {});
        }, 2000);
      } else {
        clearInterval(this.timer);
      }
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
  created() {
    this.External();
  },
};
</script>

<style lang="scss" scoped>
.m-box {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(204, 204, 204, 0.2);
  border-radius: 6px;
  border: 1px solid #f0f0f0;
}
.m-head {
  padding: 30px 40px;

  .m-tab {
    height: 202px;
    border: 1px solid #e2e2e2;
    .con_title {
      height: 50px;
      background-color: #f8f8f8;
    }
    .con_main {
      .icon {
        width: 12px;
        height: 12px;
        background: #ff9905;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        .triangle {
          border-style: solid;
          border-color: transparent;
          border-width: 3px 0 3px 3px;
          border-left-color: #ffffff;
        }
      }
      .img_box {
        width: 141px;
        height: 92px;
      }
    }
  }
}
.pay_con {
  height: 220px;

  .pay_box {
    .pay {
      width: 300px;
      height: 100px;
      border: 1px solid #e2e2e2;
      background: url("../assets/images/noaff.png") no-repeat;
    }
    .active {
      border: 1px solid #ff9606;
      background: url("../assets/images/aff.png") no-repeat;
      background-position: -5px -2px;
    }
  }
}
</style>