<template>
  <div>
    <Modal
      class="no-login-box"
      v-model="payData"
      :closable="false"
      :scrollable="true"
      :footer-hide="true"
      :mask-closable="false"
      :styles="{ top: '300px' }"
      width="360"
      @on-visible-change="qrDialogChange"
    >
      <p class="item-close" @click="payData = false">
        <img src="../assets/images/x.png" alt="" />
      </p>
      <p class="item-img">
        <img :src="payImg" alt="" />
      </p>
      <div class="tip-bg">
        <p>请使用{{ payRemark }}扫描</p>
        <p>二维码以完成支付</p>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "pay",
  data() {
    return {
      payData: false,
      payImg: true,
      payRemark:"1"
    };
  },
  methods: {
    qrDialogChange() {},
  },
};
</script>

<style lang="scss" scoped>
/*扫码支付*/
.no-login-box {
  text-align: center;
  .item-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -14px;
    top: -25px;
    cursor: pointer;
  }
  .item-img {
    width: 302px;
    height: 302px;
    margin: 20px auto auto auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tip-bg {
    width: 182px;
    height: 62px;
    background: url("../assets/images/x.png");
    margin: 30px auto auto auto;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
    p:first-child {
      padding-top: 9px;
    }
  }
}
.no-login-box /deep/ .ivu-modal-content {
  position: relative;
  height: 450px;
}
/*扫码支付*/
</style>