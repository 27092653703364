<template>
  <div>
    <!--已购买弹框-->
    <Modal
      class="no-login-box"
      v-model="isShow"
      :closable="false"
      :scrollable="true"
      :footer-hide="true"
      :styles="{ top: '300px' }"
      width="300"
    >
      <p class="item-close" @click="isShowBtn()">
        <img src="../assets/images/x.png" alt="" />
      </p>
      <p class="item-img">
        <img src="../assets/images/succ.png" alt="" />
      </p>
      <p class="item-txt-1">购买成功</p>
      <p class="item-txt-2">恭喜您已成功购买喜欢的课程</p>
      <router-link class="item-button" to="/Personal/Purchase"
        >去学习</router-link
      >
    </Modal>
    <!--已购买弹框-->
  </div>
</template>

<script>
export default {
  name: "ModelSucc",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // isShow: false,
    };
  },
  methods: {
    isShowBtn() {
      this.$emit("faBtn");
    },
  },
};
</script>

<style lang="scss" scoped>
.no-login-box {
  text-align: center;
  .item-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -21px;
    top: -21px;
    cursor: pointer;
  }
  .item-img {
    width: 148px;
    height: 81px;
    margin: 37px auto auto auto;
  }
  .item-txt-1 {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    padding-top: 18px;
  }
  .item-txt-2 {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    padding-top: 6px;
  }
  .item-button {
    display: block;
    width: 250px;
    height: 40px;
    background: #ff9606;
    box-shadow: 0px 2px 6px 0px rgba(192, 106, 0, 0.3);
    border-radius: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    margin: 35px auto auto auto;
  }
}
.no-login-box /deep/ .ivu-modal-content {
  position: relative;
  height: 300px;
}
</style>